import React, { useContext, useRef } from "react";
import { connectRefinementList, Panel } from "react-instantsearch-dom";
import classNames from "classnames";
import { JobsContext, SearchContext } from "context/providers";
import { DEFAULT_BH_STATUSES_TO_EXCLUDE } from "lookup";

const getActiveValues = (BHStatusFilterStr = "") => {
  return [
    ...BHStatusFilterStr.matchAll(/bullhornStatus:"((?:\\.|[^"\\])*)"/g),
  ].map((m) => m[1].replace(/\\"/g, '"'));
};

const getBHStatusFilterStr = (newActiveStatus) => {
  return newActiveStatus
    .map((value) => {
      return `NOT bullhornStatus:"${value}"`;
    })
    .join(" AND ");
};

const getInitialToExclude = () => {
  const obj = {};

  for (const status of DEFAULT_BH_STATUSES_TO_EXCLUDE || []) {
    obj[status] = { label: status, isRefined: true };
  }

  return obj;
};

const CustomBHStatusFilter = ({ items, isReadOnly }) => {
  const { searchState, setSearchState } = useContext(SearchContext);
  const { jobOpp } = useContext(JobsContext);

  const itemsObjectRef = useRef(jobOpp?.id ? getInitialToExclude() : {});

  const handleExcludeStatusFilterChange = (item, checked) => {
    const itemLabel = item.label;
    let newFilter;

    const activeValues = getActiveValues(searchState.BHStatusFilterStr);

    if (checked) {
      newFilter = [...activeValues, itemLabel];

      itemsObjectRef.current[itemLabel] = {
        ...item,
        isRefined: true,
      };
    } else {
      newFilter = activeValues.filter((item) => item !== itemLabel);
      itemsObjectRef.current[itemLabel] = { ...item, isRefined: false };
    }

    const filterStrValue = getBHStatusFilterStr(newFilter);

    setSearchState({
      ...searchState,
      BHStatusFilterStr: filterStrValue ? ` AND (${filterStrValue})` : "",
    });
  };

  const handleChange = (item, checked) => {
    handleExcludeStatusFilterChange(item, checked);
  };

  const getFinalItems = (BHStatusFilterStr) => {
    const activeValues = getActiveValues(BHStatusFilterStr);

    const newItems = items.map((item) => {
      return {
        ...item,
        isRefined: activeValues.includes(item.label),
      };
    });

    for (const activeValue of [
      ...Object.keys(itemsObjectRef.current || {}),
      ...activeValues,
    ]) {
      const newItem = itemsObjectRef.current[activeValue];
      if (!newItems.some((e) => e.label === activeValue) && newItem) {
        newItems.unshift({
          ...newItem,
          isRefined: activeValues.includes(newItem.label),
        });
      }
    }

    if (isReadOnly) {
      return newItems.filter((e) => activeValues.includes(e.label));
    }

    return newItems;
  };

  const finalItems = getFinalItems(searchState.BHStatusFilterStr);

  return (
    <div>
      <ul className="ais-RefinementList-list">
        {finalItems.map((item) => (
          <li
            key={item.label}
            className={classNames("mt-1 ais-RefinementList-item", {
              "ais-RefinementList-item--selected": item.isRefined,
            })}
          >
            <label
              className={classNames(
                "flex items-center text-sm whitespace-nowrap ais-RefinementList-label min-h-[22px]",
                {
                  "cursor-not-allowed opacity-85": isReadOnly,
                }
              )}
            >
              <input
                type="checkbox"
                className={classNames("ais-RefinementList-checkbox", {
                  "cursor-not-allowed !bg-gray-100": isReadOnly,
                })}
                checked={item.isRefined}
                onChange={(e) => handleChange(item, e.target.checked)}
                disabled={isReadOnly}
              />
              <span
                className={classNames(
                  "mx-2 overflow-hidden text-ellipsis capitalize",
                  {
                    "font-bold": item.isRefined,
                    "cursor-not-allowed": isReadOnly,
                  }
                )}
              >
                {item.label}
              </span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

const BHStatusFilter = connectRefinementList(CustomBHStatusFilter);

const BHStatusRefinementWrapper = ({ isReadOnly }) => {
  return (
    <Panel header="BH Placed Excluded">
      <BHStatusFilter
        attribute="bullhornStatus"
        isReadOnly={isReadOnly}
        limit={15}
      />
    </Panel>
  );
};

export default BHStatusRefinementWrapper;
