import React, { useContext, useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { nanoid } from "nanoid";
import { isEqual } from "lodash";
import Select from "react-select";
import { SearchContext } from "context/providers";
import { validateSearchCriteriaTitle } from "utils/validation";

import Input from "components/base/Input";
import Button from "components/Button";
import Collapsible from "./Collapsible";

const SAVED_CRITERIA_KEY = "SEARCH_CRITERIA";

const SavedSearches = () => {
  const {
    searchState,
    setSearchState,
    AISkillSearchConfig,
    AISkillsConfigSearchChange,
    clearAISkillsSearchConfig,
  } = useContext(SearchContext);

  const [inputs, setInputs] = useState({ criteriaTitle: "" });
  const [errors, setErrors] = useState({ criteriaTitle: "" });
  const [selectedCriteria, setSelectedCriteria] = useState(null);
  const allowCheckStateChange = useRef(true);

  useEffect(() => {
    if (!selectedCriteria?.value) {
      return;
    }

    if (!allowCheckStateChange.current) {
      return;
    }

    allowCheckStateChange.current = false;

    const savedSearches = getSavedSearches();

    const currentCriteria = savedSearches.find(
      (e) => e.id === selectedCriteria.value
    );

    if (!isEqual(currentCriteria.criteria, searchState)) {
      setSelectedCriteria(null);
    }

    setTimeout(() => {
      allowCheckStateChange.current = true;
    }, 1500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState]);

  const getSavedSearches = () =>
    JSON.parse(localStorage.getItem(SAVED_CRITERIA_KEY) || "[]");

  const restoreSearchCriteria = (id) => {
    const savedSearches = getSavedSearches();

    const savedSearch = savedSearches.find((el) => el.id === id);

    setSearchState(savedSearch?.criteria);

    if (savedSearch?.AISkillSearchConfig?.enabled) {
      AISkillsConfigSearchChange(savedSearch.AISkillSearchConfig);
    } else if (AISkillSearchConfig?.enabled) {
      clearAISkillsSearchConfig();
    }
  };

  const renderSavedSearch = () => {
    const savedSearches = getSavedSearches();

    if (savedSearches.length === 0) {
      return null;
    }

    return (
      <div className="mb-3">
        <Select
          value={selectedCriteria}
          options={savedSearches.map((savedSearch) => ({
            value: savedSearch.id,
            label: savedSearch.title,
          }))}
          onChange={(input) => {
            setSelectedCriteria(input);
            restoreSearchCriteria(input.value);
          }}
        />
      </div>
    );
  };

  const validateTitle = (e) => {
    e.preventDefault();

    validateSearchCriteriaTitle({
      data: inputs,
      onSuccess: (validData) => saveSearchCriteria({ ...validData }),
      onError: (errorsData) => {
        setErrors({ ...errorsData });
      },
    });
  };

  const saveSearchCriteria = () => {
    try {
      const savedCriteria = JSON.parse(
        localStorage.getItem(SAVED_CRITERIA_KEY) || "[]"
      );

      const newId = nanoid();
      savedCriteria.push({
        title: inputs.criteriaTitle,
        criteria: searchState,
        AISkillSearchConfig,
        id: newId,
      });

      localStorage.setItem(SAVED_CRITERIA_KEY, JSON.stringify(savedCriteria));
      setInputs((prev) => ({ ...prev, criteriaTitle: "" }));
      setSelectedCriteria({ value: newId, label: inputs.criteriaTitle });

      toast("Search criteria was saved");
    } catch (error) {
      console.warn(`saveSearchCriteria error: ${error}`);
    }
  };

  const handleCriteriaTitleChange = ({ value, valueKey }) => {
    setInputs((prev) => ({ ...prev, [valueKey]: value }));
    setErrors((prev) => ({ ...prev, [valueKey]: "" }));
  };

  return (
    <>
      <Collapsible label="Saved Searches">
        <form onSubmit={validateTitle}>
          {renderSavedSearch()}
          <Input
            label="Save Current Criteria"
            value={inputs.criteriaTitle}
            valueKey="criteriaTitle"
            errorMessage={errors.criteriaTitle}
            placeholder="Name"
            onChange={handleCriteriaTitleChange}
          />

          <Button className="mb-3" type="submit">
            save
          </Button>
        </form>
      </Collapsible>
    </>
  );
};

export default SavedSearches;
